import {
    ADD_TO_CART_START,
    ADD_TO_CART_SUCCESS,
    SET_TOKEN,
    GET_CONTACTS,
    GET_FILTERS_ACTION,
    GET_CART_ITEMS_START
} from "./Actions";
import axiosFetch from "../helpers/axios"
import { ADD_ITEM_TO_CART, GET_FILTERS, GET_CONTACTS_INFO, GET_CART_ITEMS, GET_CART_DATA } from "../helpers/constants"


export function getContacts() {

    const contactsResult = axiosFetch(GET_CONTACTS_INFO)
    return dispatch => {
        return Promise.resolve(contactsResult).then((response) => {
            dispatch({
                type: GET_CONTACTS,
                payload: response[0]
            })
        })
    }
}

export function getFilters() {

    const filtersResult = axiosFetch(GET_FILTERS)
    return dispatch => {
        return Promise.resolve(filtersResult).then((response) => {
            dispatch({
                type: GET_FILTERS_ACTION,
                payload: response
            })
        })
    }
}


export function addItemToMyCart(payload) {
    console.log("loadAddItemToMyCart", payload)
    return dispatch => {
        dispatch({ type: ADD_TO_CART_START });
        
        return loadAddItemToMyCart(dispatch, payload);
    }
}

const loadAddItemToMyCart = (dispatch, payload) => {

    const {
        id,
        count,
        token,
        price
    } = payload

   

    const formData = new FormData()
    formData.append("productId", id)
    formData.append("count", count)
    formData.append("price", price)
    formData.append("token", token)
    console.log("ADD_ITEM_TO_CART", ADD_ITEM_TO_CART, formData)
    const cartList = axiosFetch(ADD_ITEM_TO_CART, formData)

    Promise.resolve(cartList).then((response) => {
        dispatch({
            type: ADD_TO_CART_SUCCESS,
            payload: response[0]
        })
    })

};


export function setGlobalToken(payload) {
    return dispatch => {
        return loadSetToken(dispatch, payload);
    }
}

const loadSetToken = (dispatch, payload) => {
    dispatch({
        type: SET_TOKEN,
        payload
    })
};

export function getCartInfoFirstLoad(payload) {
    return dispatch => {
        dispatch({ type: ADD_TO_CART_START });
        return loadGetCartInfoFirstLoad(dispatch, payload);
    }
}

const loadGetCartInfoFirstLoad = (dispatch, payload) => {
    const formData = new FormData()
    formData.append("token", payload)

    const cartList = axiosFetch(GET_CART_DATA, formData)

    Promise.resolve(cartList).then((response) => {
        setTimeout(() => {
            dispatch({
                type: ADD_TO_CART_SUCCESS,
                payload: response[0]
            })
        }, 500);

    })
}

export function getCartData(payload) {

    return dispatch => {
        return loadGetCartData(dispatch, payload);
    }
}

const loadGetCartData = (dispatch, payload) => {
    const formData = new FormData()
    formData.append("token", payload)

    const cartList = axiosFetch(GET_CART_ITEMS, formData)
    Promise.resolve(cartList).then((response) => {

        dispatch({
            type: GET_CART_ITEMS_START,
            payload: response
        })


    })
}