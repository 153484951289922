import React from "react";
import HeaderNavBar from "../header/header-nav-bar";
import HeaderTopBar from "../header/header-top-bar";
import HeaderMiddleBar from "../header/header-middle-bar";

const Header = () => {
  return (
    <header className="header-style-1">
      <HeaderTopBar />
      <HeaderMiddleBar />
      {/* <HeaderNavBar /> */}
    </header>
  );
};
export default Header;
