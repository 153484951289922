import React from 'react';
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { setGlobalCurrency, changeLanguage } from "../../../actions/MenuDataActionCreators"
import { setLoginAuth } from "../../../actions/SetLoginAuthActionCreators"
import { withNamespaces } from "react-i18next";
import langRu from "../../../images/ru.png"
import langRo from "../../../images/md.png"
//import Button from '../../../helpers/button'
import SearchField from "../header-middle-bar/search-field"
import './styles.scss'

function HeaderTopBar(props) {
  const { setGlobalCurrency, currency, changeLanguage, lng, menuData: { menu = [] }, t } = props
  //const [showTheme, setShowtheme] = useState(true)

  // const renderLoginInfo = () => {
  //   const { name } = loginData

  //   return (
  //     <span style={{ color: "#fff" }}>
  //       <Link to="/profile">
  //         {name}
  //       </Link>
  //       <b className="exit-btn" onClick={() => exit()}> ({t('Exit')}) </b>
  //     </span>
  //   )
  // }

  // const renderAuthLink = () => {
  //   return (
  //     <Link to="/auth">
  //       <i className="icon fa fa-lock"></i>{t('Autorization')}
  //     </Link>
  //   )
  // }

  // const exit = () => {
  //   CookieHelper('remove', 'userEmail');
  //   CookieHelper('remove', 'userName');
  //   const data = {}
  //   setLoginAuth(data)
  // }

  // const themesStyles = () => {
  //   if(showTheme){
  //     document.getElementById("html").classList.add('white-theme');
  //   }else{
  //     document.getElementById("html").classList.remove('white-theme');
  //   }

  //   setShowtheme(!showTheme)
  // }


  const renderMenuItems = (menu) => {
    const menuContent = menu.map((menuItem, index) => {
      const { name, subCategories, label, labelColor, categoryImage, url, nameRo, labelRo } = menuItem
      const finalName = lng === "ru" ? name : nameRo
      const finalLabel = lng === "ru" ? label : labelRo
      return (
        <li className="mega-menu" key={index}>

          <Link to={url} >

            {finalName}

            {finalLabel &&
              <span className="menu-label hot-menu hidden-xs" style={{ backgroundColor: labelColor }}>
                {finalLabel}
              </span>
            }
          </Link>
          {/* {subCategories.length > 0 && renderSubcategoryMenu(subCategories, categoryImage)} */}

        </li>
      )
    })

    return menuContent
  }

  const currentLang = lng === "ru" ? langRu : langRo

  return (
    <div className="top-bar animate-dropdown">
      <div className="container">
        <div className="header-top-inner main-header">
        <SearchField {...{ t }} />
          <div className="cnt-account">

          
         
              {/* <ul className="nav navbar-nav">
                {menu.length && renderMenuItems(menu)}
              </ul> */}

            {/* {loginData.name &&
                <li>
                  <Link to="/my-account">
                    <i className="icon fa fa-user"></i>{t('My_account')}
                  </Link>
                </li>
              } */}
            {/* <li>
                <Link to="/my-favorites">
                  <i className="icon fa fa-heart"></i>Фавориты
                  </Link>
              </li> */}
            {/* <li>
                <Link to="/cart">
                  <i className="icon fa fa-shopping-cart"></i>{t('Cart')}
                </Link>
              </li>
              <li>
                {loginData.name ? renderLoginInfo() : renderAuthLink()}
              </li> */}

          </div>

          <div className="cnt-block">
            <ul className="list-unstyled list-inline">
              {/* <li>
                <Button
                  type="button"
                  className="btn-upper btn btn-primary checkout-page-button"
                  text={showTheme ? 'Показать светлую тему' : 'Показать тёмную тему'}
                 // disabled={!login || !password}
                  onClick={() => themesStyles()}
                />
              </li> */}
              <li className="dropdown dropdown-small">
                <a
                  href="/"
                  className="dropdown-toggle"
                  data-hover="dropdown"
                  data-toggle="dropdown"
                >
                  <span className="value">{currency} </span>
                  <b className="caret"></b>
                </a>
                <ul className="dropdown-menu currency-menu">
                  <li>
                    <span onClick={() => setGlobalCurrency("USD")}>USD</span>
                  </li>
                  <li>
                    <span onClick={() => setGlobalCurrency("EURO")}>EURO</span>
                  </li>
                  <li>
                    <span onClick={() => setGlobalCurrency("MDL")}>MDL</span>
                  </li>
                </ul>
              </li>

              <li className="dropdown dropdown-small languages-container">
                <a
                  href="/"
                  className="dropdown-toggle"
                  data-hover="dropdown"
                  data-toggle="dropdown"
                >
                  <span className="value"><img src={currentLang} alt={currentLang} /> </span>
                  <b className="caret"></b>
                </a>
                <ul className="dropdown-menu languages">
                  <li onClick={() => changeLanguage("ru")}>
                    <span><img src={langRu} alt={langRu} /></span>
                  </li>
                  <li onClick={() => changeLanguage("ro")}>
                    <span><img src={langRo} alt={langRo} /></span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  currency: state.MenuDataReducer.currency,
  menuData: state.MenuDataReducer,
  lng: state.PrefsReducer.lng,
  loginData: state.SetLoginReducer.loginData,
});

export default withRouter(compose(connect(mapStateToProps, {
  setGlobalCurrency,
  setLoginAuth,
  changeLanguage
}), withNamespaces())(HeaderTopBar));

