import React, { useState } from "react";
import { showCurrencyPrice } from "../../../../helpers/constants/functions";
import { withSnackbar } from 'notistack';
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../../../../helpers/spinner";
import DeliveryForm from "./delivery-form"

import "./styles.scss";

function CartTable(props) {
  const { cartItems, currency, cartData: { totalSumm }, minSummOrder, freeDeliveryFrom, deliveryPrice, redirect, lng, t } = props;

  const [delivery, setDelivery] = useState('delivery')

  const renderCartItemsTable = () => {
    const content = (cartItems || []).map((cartItem, index) => {
      const { nameRu, nameRo, count, id, image, price, productCount } = cartItem;
      const finalProductName = lng === "ru" ? nameRu : nameRo
      return (
        <div className="checkout-line " key={index}>
          <div className="checkout-product col-md-2">
            <Link to={`/details/${id}`}>
              <div
                className="product-image-container"
                style={{ backgroundImage: `url(${image})` }}
              />
            </Link>
          </div>
          <div className="col-md-2 checkout-name">
            <Link to={`/details/${id}`}>{finalProductName}</Link>
          </div>
          {/* <div className="col-md-2 count-styles">{productCount}</div> */}
          <div className="col-md-2 count-styles">{count} </div>
          <div className="col-md-2 count-styles"> {showCurrencyPrice(price, currency)} {currency} </div>
          <div className="col-md-2 count-styles total-f-w"> {showCurrencyPrice(price * count, currency)} {currency}</div>
        </div>
      );
    });

    return content;
  };

  const isDelivery = delivery === "delivery"
  const finishSumm = isDelivery ? Number(totalSumm) + Number(deliveryPrice) : totalSumm

  const isFreeDelivery = Number(freeDeliveryFrom) <= Number(totalSumm)

  const deliveryPriceFinal = isFreeDelivery ? t("Free") : `${deliveryPrice} ${currency}`
  const totalSummFinal = isFreeDelivery ? `${totalSumm} ${currency}` : `${finishSumm} ${currency}`

  const deliveryForm = () => {
    return (
      <div className="order-info">

        <div className="total-table-summ">
          <span className="checkout-total-count">{totalSumm} {currency}</span>
        </div>

        <div className="checkout-total">
          <div className="delivery-container">
            <div className="fields-block">
              <div>{t('Delivery_method')}</div>
              <div className="shipping">
                <div>
                  <ul id="shipping_method"><li>
                    <input
                      type="radio"
                      name="delivery"
                      checked={isDelivery}
                      onChange={() => setDelivery("delivery")}
                      id="delivery"
                    />
                    <label htmlFor="delivery">{t('Delivery')}</label></li>
                    <li>
                      <input type="radio" name="delivery"
                        checked={delivery === "samovivoz"}
                        onChange={() => setDelivery("samovivoz")}
                        id="samovivoz" />
                      <label htmlFor="samovivoz">{t('Pickup')}</label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="total-container">

            {isDelivery && freeDeliveryFrom > totalSumm && <div className="total-parent free-delivery">
              <span className="checkout-total-label">{t('Free_delivery')}</span>
              <span className=""> {freeDeliveryFrom - totalSumm}{currency}</span>
            </div>}

            {isDelivery && <div className="total-parent">
              <span className="checkout-total-label">{t('Delivery')}: </span>
              <span className="checkout-total-count">{deliveryPriceFinal}</span>
            </div>}
            <div className="total-parent">
              <span className="checkout-total-label">{t('Total')}: </span>
              <span className="checkout-total-count">{totalSummFinal}</span>
            </div>
          </div>
        </div>
        <DeliveryForm
          {...{ cartItems }}
          {...{ totalSumm }}
          {...{ deliveryPriceFinal }}
          {...{ totalSummFinal }}
          deliveryType={delivery}
          {...{ redirect }}
          {...{ t }}
        />
        {/* <b>{t('Min_summ_delivery_change', { deliveryPrice, currency })}</b> */}

      </div>
    )
  }

  const minSummContent = () => {
    return (
      <h3 style={{ marginTop: "30px" }}>{t('Min_summ_delivery', { minSummOrder, currency })}</h3>
    )
  }



  const renderContent = () => {
    return (
      <div className="checkout-page">
        <div className="checkout-line">
          <div className="checkout-product col-md-2 f-w-700">{t('Table_cart_image')} </div>
          <div className="checkout-name col-md-2">{t('Table_cart_product_name')} </div>
          <div className="col-md-2 f-w-700 count-styles">{t('Table_cart_qty')} </div>
          {/* <div className="col-md-2 f-w-700 count-styles">{t('Table_cart_qty_items')} </div> */}
          <div className="col-md-2 f-w-700 count-styles">{t('Table_cart_product_price')} </div>
          <div className="col-md-2 f-w-700 count-styles">{t('Total')} </div>
        </div>
        {cartItems.length > 0 && renderCartItemsTable()}
        {totalSumm >= minSummOrder ? deliveryForm() : minSummContent()}
      </div>
    )
  }

  const content = minSummOrder === 0 ? <Spinner /> : renderContent()
  return content
}

const mapStateToProps = (state) => ({
  minSummOrder: state.GlobalSettingsReducer.minSummOrder,
  freeDeliveryFrom: state.GlobalSettingsReducer.freeDeliveryFrom,
  deliveryPrice: state.GlobalSettingsReducer.deliveryPrice,
});


export default withRouter(connect(mapStateToProps, {})(withSnackbar(CartTable)));


