import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../../../helpers/spinner";
import {
  amountFormatter,
  showCurrencyPrice,
} from "../../../../helpers/constants/functions";
import { DELETE_CART_ITEM } from "../../../../helpers/constants";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import axiosFetch from "../../../../helpers/axios";
import CookieHelper from "../../../../helpers/cookie-helper";
import {
  getCartData,
  getCartInfoFirstLoad,
} from "../../../../actions/AddToCartActionCreator";
import { withNamespaces } from "react-i18next";
import { compose } from "redux";

import "./styles.scss";

function Cart(props) {
  const {
    isFetching,
    cartData: { totalItems, totalSumm },
    currency,
    cartItems = {},
    getCartData,
    getCartInfoFirstLoad,
    t,
    lng
  } = props;

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 500);
  }, []);

  const [isOpening, setIsOpening] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteProductId, setDeleteProduct] = useState(0);

  const price = showCurrencyPrice(totalSumm, currency);

  const renderCartItems = (cartArr) => {
    const content = cartArr.map((oneItem, index) => {
      const { nameRu, nameRo, price, url, image, count, cartLineId } = oneItem;
      const finalProductName = lng === "ru" ? nameRu : nameRo
      return (
        <div
          className={classNames({
            "cart-item": true,
            "product-summary": true,
            "in-deleting": deleteProductId === cartLineId,
          })}
          key={index}
        >
          {deleteProductId === cartLineId && showDeleteProductForm()}
          {deleteProductId === cartLineId && isLoading && <Spinner />}
          <div className="row">
            <div className="col-xs-4">
              <Link
                to={url}
                className="product"
                style={{
                  backgroundImage: `url(${image})`,
                }}
              />
            </div>
            <div className="col-xs-7">
              <h3 className="name">
                <Link to={url}>{finalProductName}</Link>
              </h3>
              <div className="price">
                {showCurrencyPrice(price, currency)} {currency} x{count}
              </div>
              {count > 1 && (
                <div className="above-one-count">
                  {showCurrencyPrice(price * count, currency)}
                  {currency}
                </div>
              )}
            </div>
            <div className="col-xs-1 action">
              <i
                className="fa fa-trash"
                onClick={() => setDeleteProduct(cartLineId)}
              />
            </div>
          </div>
        </div>
      );
    });
    return content;
  };

  const deleteItemFromCart = () => {
    setIsLoading(true);
    const token = CookieHelper("get", "token");
    const formData = new FormData();
    formData.append("lineId", deleteProductId);

    axiosFetch(DELETE_CART_ITEM, formData).then(() => {
      getCartData(token);
      getCartInfoFirstLoad(token);
      setIsLoading(false);
      setDeleteProduct();
    });
  };

  const showDeleteProductForm = () => {
    return (
      <div className="cart-review-delete-container">
        <span>{t('Delete')}?</span>
        <button
          className="btn btn-primary btn-upper"
          onClick={() => deleteItemFromCart()}
        >
          OK
        </button>
        <button
          className="btn btn-primary btn-upper"
          onClick={() => setDeleteProduct()}
        >
          {t('Cancel')}
        </button>
      </div>
    );
  };
  const renderEmptyContent = () => {
    return <div className="empty-content">{t('Cart_empty')}</div>;
  };

  const renderCartMenuItems = () => {
    return (
      <ul className="dropdown-menu" onClick={(e) => e.stopPropagation()}>
        <li>
          <div className="cart-items-container">
            {cartItems.length
              ? renderCartItems(cartItems)
              : renderEmptyContent()}
          </div>

          <div className="clearfix"></div>
          {cartItems.length ? renderTotalAndCheckout() : ""}
        </li>
      </ul>
    );
  };

  const renderTotalAndCheckout = () => {
    return (
      <>
        <hr />
        <div className="clearfix cart-total">
          <div className="pull-right">
            <span className="text">Итого :</span>
            <span className="price">
              {price} {currency}
            </span>
          </div>
          <div className="clearfix"></div>

          <Link
            to="/cart"
            className="btn btn-upper btn-primary btn-block m-t-20 checkout-button"
          >
           {t('Checkout')}
          </Link>
        </div>
      </>
    );
  };

  const renderTotalCartInfo = () => {
    return (
      <a href="/" className="dropdown-toggle lnk-cart" data-toggle="dropdown">
        <div className="items-cart-inner">
          <div className="basket">
            <i className="glyphicon glyphicon-shopping-cart"></i>
          </div>
          <div className="basket-item-count">
            <span className="count">{totalItems}</span>
          </div>
          <div className="total-price-basket">
            <span className="total-price">
              <span className="value">{amountFormatter(price)}</span>
              <span className="sign"> {currency}</span>
            </span>
          </div>
        </div>
      </a>
    );
  };

  return (
    <div className="col-xs-12 col-sm-12 col-md-2 animate-dropdown top-cart-row">
      <div
        className={classNames({
          //"open-cart-items": isOpening,
          //'dropdown': true,
          "dropdown-cart": true,
        })}
        onClick={() => setIsOpening(!isOpening)}
      >
        {isFetching && <Spinner />}
        {!isFetching && renderTotalCartInfo()}
      </div>
      {!isFetching && renderCartMenuItems()}
    </div>
  );
}

const mapStateToProps = (state) => ({
  currency: state.MenuDataReducer.currency,
  isFetching: state.AddToCartReducer.isFetching,
  cartData: state.AddToCartReducer.cartData,
  cartItems: state.AddToCartReducer.cartItems,
  lng: state.PrefsReducer.lng,
});

export default withRouter(compose(connect(mapStateToProps, {
  getCartData,
  getCartInfoFirstLoad
}), withNamespaces())(Cart));
