import React, { Component } from "react";
import Header from "../../header";
import Categories from "../widgets/categories"
//import HotDeals from "../widgets/hot-deals"
//import Tags from "../widgets/tags"
// import SpecialDeal from "../widgets/special-deal"
// import NewsLatter from "../widgets/newslatter"
// import Testimonials from "../widgets/testimonials"
import Slider from "../widgets/slider"
//import NewProducts from "../widgets/new-products"
import FeaturedProducts from "../widgets/featured-products"
//import News from "./news"
//import Banner from "../widgets/banner"
//import BestSeller from "../widgets/best-seller"
//import LatestFromBlog from "../widgets/latest-from-blog"
//import NewArrivals from "../widgets/new-arrivals"
//import BrandsCarousel from "../widgets/brands-carousel"
import CustomCarousel from "../../../helpers/custom-carousel"
import Footer from "../../footer"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import OneProduct from '../../../helpers/one-product'

import "./styles.scss"
function HomePage(props) {

  const { menuData: { menu }, lng, globalSearchResult } = props

  const renderCategoriesContent = () => {

    const content = (menu || []).map((item, i) => {
      console.log("menu", menu, globalSearchResult)
      const { name, subCategories, label, labelColor, categoryImage, url, nameRo, labelRo, id } = item
      const finalName = lng === "ru" ? name : nameRo
      const categoryProducts = globalSearchResult.filter(item => item.category === id)

      const productsArr = []

      categoryProducts.forEach((oneProduct, index) => {
        productsArr.push({
          oneProduct: <OneProduct oneProduct={oneProduct} className={"item"} showAddToCart={true} key={index} />,
        })
      })

      if(categoryProducts.length > 0) {
        return (
          <div className="sidebar-carousel">
            <h2>{finalName}</h2>
            {/* {productsList && productsList.length > 0 && renderProductsItems(productsList)} */}
            <CustomCarousel swipeable={true}
              draggable={true}
              showDots={false}
              arrows={true}
              infinite={true}
              autoPlay={true}
              desktopItems={3}
              autoPlaySpeedInSec={i + 2.5}
              carouselItems={productsArr}
            />
          </div>
        )
      } 
      
    })
    return content
  }




  return (
    <div className="cnt-home">
      <Header />
      <div className="body-content outer-top-xs" id="top-banner-and-menu">
        <div className="container home-page">
          <Slider />
          {/* <Categories isHomePage={true}/> */}
          <div className="row blog-page">
            {globalSearchResult && renderCategoriesContent()}
            {/* <News itemsOnPage={3}/> */}
            {/* <div className="col-xs-12 col-sm-12 col-md-3 sidebar">
               <Categories />
                <HotDeals />
                <Tags />
                <SpecialDeal />
                <NewsLatter />
                <Testimonials /> 
              </div>*/}
            <div className="col-xs-12 col-sm-12 col-md-12 homebanner-holder">
              {/* <Slider /> */}
              {/* <NewProducts /> */}
              <FeaturedProducts />
              {/* <Banner />
                <BestSeller /> */}
              {/* <LatestFromBlog /> */}
              {/* <NewArrivals /> */}
            </div>
          </div>
          {/* <BrandsCarousel /> */}
        </div>
      </div>
      <Footer />
    </div>
  );

}


const mapStateToProps = (state) => ({
  menuData: state.MenuDataReducer,
  lng: state.PrefsReducer.lng,
  globalSearchResult: state.MenuDataReducer.globalSearchResult,
});

export default withRouter(
  compose(
    connect(mapStateToProps, {}),
    withNamespaces()
  )(HomePage)
);